@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body,
html,
.App {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  color: #fff;
  font-family: 'Lato', sans-serif;
  overflow: hidden; /* Prevents scrolling */
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shape {
  position: absolute;
  width: 100px;
  height: 100px;
  z-index: 1;
}

.sun {
  top: 10%;
  left: 10%;
}

.star1 {
  top: 20%;
  right: 20%;
}

.checkers {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.see-more {
  position: absolute;
  right: 40px;
  bottom: 20px;
}

.accent-square {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
  height: 45%;
  background-color: #2d2d2d;
  z-index: 0;
}

.profile-section {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1300px;
  text-align: center;
  gap: 20px;
  z-index: 0;
  position: absolute;
  width: 100%;
  height: auto;
  padding: 100px 20px;
  background-color: #161616;
  border-radius: 22px;
}

.text-section {
  max-width: 60%;
}

.image-section {
  position: relative;
  height: 300px;
  width: 300px;
}

.image-bg {
  background-color: #87ceeb;
  border-radius: 50%;
  position: absolute;
  height: 285px;
  width: 285px;
  right: -6px;
  bottom: -23px;
}

.profile-pic {
  border-radius: 50%;
  width: 300px;
  filter: grayscale(100%);
  border: 15px solid #161616;
}

.name {
  font-family: 'Rubik Glitch', sans-serif;
  font-size: 5em;
  margin: 0.5rem 0 0 0;
  font-weight: 400;
}

.title {
  font-size: 3em;
  font-weight: 100;
  margin: 0.5em 0;
}

.bio {
  font-size: 1.4em;
}

.nav-title {
  font-family: 'Rubik Glitch', sans-serif;
  font-size: 2em;
  margin: 0 0.5em;
}

.nav-links {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-links button {
  background: none;
  border: none;
  color: #fff;
  font-size: 0.9em;
  margin: 0 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 200;
  transition: color 0.3s;
}

.nav-links button:hover {
  color: #a3a3a3;
}

.nav-links button.active {
  color: #87ceeb;
}

/* Updated scrolling-title styles for full screen scrolling */
.scrolling-title {
  position: absolute;
  z-index: 1;
  width: 100%;
  white-space: nowrap;
  animation: scroll-horizontal 20s linear infinite;
}

.scrolling-title-sideways {
  position: absolute;
  z-index: 1;
  height: 100%;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  white-space: nowrap;
  animation: scroll-vertical 30s linear infinite;
}

.title1 {
  top: 50%;
  left: 10%;
}

.title2 {
  bottom: 0;
  right: 10%;
}

.title3 {
  bottom: 10%;
  left: 70%;
}

.title4 {
  bottom: 20%;
  left: 10%;
}

@keyframes scroll-horizontal {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll-vertical {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.project-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: absolute;
  align-items: flex-start;
  gap: 20px;
  background-color: #161616;
  padding: 50px 50px;
  border-radius: 22px;
  box-sizing: border-box;
  max-height: 90vh;
  z-index: 1;
}

.project-images {
  width: 100%;
  max-width: 400px;
  margin: 20px;
  max-height: 500px;
  height: 100%;
}

.project-images-vertical {
  width: 100%;
  max-width: 300px;
  margin: 20px;
  max-height: 500px;
}

.project-image {
  border-radius: 15px;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
}

.project-image.vertical {
  max-height: 500px;
  width: 100%;
  height: auto;
}

.image-container {
  width: auto;
  max-width: 100%;
}

.project-content {
  display: flex;
  flex-direction: column;
}

.project-technologies,
.project-features {
  margin: 20px 0;
  max-width: 50%;
  height: 50%;
}

.project-technologies h3,
.project-features h3 {
  margin-bottom: 10px;
}

.project-technologies ul,
.project-features ul {
  list-style-type: none;
  padding: 0;
}

.project-technologies li,
.project-features li {
  background: #2d2d2d;
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
}

.project-technologies ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.project-technologies li {
  background: #2d2d2d;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
}

.project-links {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 2;
}

.project-links a {
  color: #87ceeb;
  text-decoration: none;
  margin: 0 10px;
  font-weight: 500;
  transition: color 0.3s;
}

.project-links a:hover {
  color: #ffffff;
}

/* App.css */

.social-links {
  margin-top: 20px;
  display: flex;
  gap: 15px;
}

.social-icon {
  color: #87ceeb;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #ffffff;
}

@media (max-height: 1000px) {
  .star1 {
    top: 10%;
    right: 10%;
  }

  .checkers {
    bottom: -40px;
    left: -40px;
  }

  .project-section {
    max-height: 75vh;
  }

  .project-features,
  .project-technologies {
    font-size: 0.9em;
    max-width: 100%;
  }
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 1210px) {
  .profile-section {
    flex-direction: column;
    padding: 50px 20px;
  }

  .text-section {
    order: 2; /* Ensure the text section comes after the image on small screens */
  }

  .image-section {
    margin-bottom: 20px;
    order: 1; /* Ensure the image section comes first on small screens */
  }

  .name {
    font-size: 3em;
  }

  .title {
    font-size: 2em;
  }

  .bio {
    font-size: 1.2em;
  }

  .nav-links {
    top: 0;
    left: 0;
    padding: 20px;
    gap: 10px;
  }

  .nav-links button {
    font-size: 0.8em;
  }

  .scrolling-title,
  .scrolling-title-sideways {
    display: none;
  }

  .accent-square {
    display: none;
  }

  .see-more {
    right: auto;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .checkers {
    width: 70px;
    height: 70px;
    bottom: 70px;
    z-index: 0;
  }

  .project-section {
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    max-height: none;
  }

  .project-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-section h2 {
    font-size: 2em;
    text-align: center;
  }

  .project-section p {
    font-size: 1em;
    text-align: center;
  }

  .project-technologies,
  .project-features {
    width: 100%;
    text-align: center;
    font-size: 0.9em;
  }

  .project-technologies ul,
  .project-features ul {
    justify-content: center;
  }

  .project-technologies li,
  .project-features li {
    font-size: 0.9em;
    display: inline-block;
    margin: 5px;
  }

  .nav-links {
    display: flex;
    flex-wrap: wrap;
  }

  .project-images {
    width: 100%;
    margin: 0 0 20px 0;
    max-height: 200px;
  }

  .project-image.vertical {
    max-height: 200px;
  }

  .project-image {
    max-height: 200px;
  }
}
